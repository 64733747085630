export const codes = {
    starting: [
        {
            title_EN: "Default app.js",
            description_EN: "",
            file: "app.js",
            code: `
const bee     = require('@bee.js/node')
const configs = require('./configs')

const app = bee.create(configs)

app.use('/api', configs.routes)

bee.start(app)
            `
        }
    ],
    models : [
    {
        title_EN: "Simple model",
        title_BR: "Modelo simples",
        description_EN: "Example of a simple model table (users).",
        description_BR: "",
        code:
`module.exports = { 
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { keys: "IDUser" }
    }
}
`
    },
    {
        title_EN: "Simple model with unique field",
        description_EN: "Example of a simple model table (users) with a Email field as unique.",
        code:
`module.exports = { 
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { keys: "IDUser", unique: "Email" }
    }
}
`
    },
    {
        title_EN: "Default data sort with globalOrder",
        description_EN: "Example of a model table (users) with a default sort by Name field.",
        code:
`module.exports = { 
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { unique: "IDUser" },
        globalOrder: "Name ASC"
    }
}
`
    },
    {
        title_EN: "Default filter with globalWhere",
        description_EN: "Example of a model table (users), with default filter by active users.",
        code:
`module.exports = { 
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { unique: "IDUser" },
        globalOrder: "Name ASC",
        globalWhere: "users.Active = true"
    }
}
`
    },
    {
        title_EN: "Default filter with globalWhere and middleware variable",
        description_EN: "Example of a model table (users), with default filter by active users and IDUser getting from middleware.",
        code:
`module.exports = { 
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { unique: "IDUser" },
        globalOrder: "Name ASC",
        globalWhere: "users.Active = true AND users.IDUser = :IDUser"
    }
}
`
    },
    {
        title_EN: "Relation models",
        description_EN: 'Example of a model with two relations tables (users and users_profiles).',
        code: 
`module.exports = { 
    users_profiles: {
        schema: {
            IDUser_profile: { type: "tinyint", ai: true },
            User_profile: { type: "string" },
        },
        indexes: { keys: "IDUser_profile" }
    },
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            IDUser_profile: { type: "tinyint" },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true },
            Active: { type: "boolean", default: true }
        },
        indexes: { keys: "IDUser", unique: "IDUser" },
        relations: { IDUser_profile: "users_profiles.IDUser_profile CASCATE" }
    }
}`
    },
    {
        title_EN: "Relation with multiple models",
        description_EN: 'Example of a model with three relations tables (users, users_profiles and users_sessions).',
        code: 
`module.exports = { 
    users_profiles: {
        schema: {
            IDUser_profile: { type: "tinyint", ai: true },
            User_profile: { type: "string" },
        },
        indexes: { keys: "IDUser_profile" }
    },
    users: {
        schema: {
            IDUser: { type: "int", ai: true },
            IDUser_profile: { type: "tinyint" },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" },
            Birth: { type: "date" },
            Gender: { type: "char", default: "'M'" },
            Phone: { type: "int", null: true }
        },
        indexes: { keys: "IDUser", unique: "IDUser" },
        relations: { IDUser_profile: "users_profiles.IDUser_profile CASCATE" }
    },
    users_sessions: {
        schema: {
            IDUser: { type: "int" },
            Token: { type: "string", length: 2048 },
            Created: { type: "datetime", default: "now" }
        },
        relations: { IDUser: "users.IDUser CASCATE" }
    }
}`
    },
    {
        title_EN: "Simple model with guid ID",
        description_EN: "Example of a simple model table (users) with a IDUser as guid (globally unique identifier).",
        code:
`module.exports = {
    users: {
        schema: {
            IDUser: { type: "guid", ai: true },
            Email: { type: "string", length: 25 }, 
            Password: { type: "password", out: false },
            Name: { type: "string" }
        },
        indexes: { keys: "IDUser", unique: "Email" }
    }
}
`
    },
    ],
    configs: [
    {
        title_EN: 'The basic configuration file',
        code: 
`module.exports = configs = {
    version: 1.0,
    port: 1987,
    models: require('./models'),
    controllers: require('./controllers'),
    middlewares: require('./middlewares'),
    routes: require('./routes'),
    databases: {
        default: {
            type: 'mysql',
            name: 'dbname',
            user: 'dbuser',
            pass: 'password',
            host: 'host'
        }
    }
}`
    },
    {
        title_EN: "Multiples data bases",
        description_EN: 'You can also set up more than one database for your application. Below is an example of configuration if your application requires a distribution with one database for standard functions and another for control for session control.',
        code: 
`module.exports = configs = {
    version: 1.0,
    port: 1987,
    models: require('./models'),
    controllers: require('./controllers'),
    middlewares: require('./middlewares'),
    routes: require('./routes'),
    databases: {
        default: {
            type: "mysql",
            name: "xxxxx",
            user: "xxxxx",
            pass: "xxxxx",
            host: "xxxxx"
        },
        sessions: {
            type: "mysql",
            name: "ZZZZZ",
            user: "ZZZZZ",
            pass: "ZZZZZ",
            host: "ZZZZZ"
        }
    }
}`
    },
    ],
    
    controllers: [
    {
        title_EN: "Simple CRUD",
        description_EN: "Exemple of CRUD users controllers.",
        code:
`const bee  = require('@bee.js/node')
const model = 'users'

module.exports.users = {

    find : async function(req, res) {
        let id  = req.params.id
        let rel = req.query.rel

        await bee.hive(req, res, model).relations(rel).find(id)
        
        bee.response()
    },

    get : async function(req, res) {
        let rel = req.query.rel

        await bee.hive(req, res, model).relations(rel).get()

        bee.response()
    },
    
    post : async function(req, res) {
        let data = req.body
        
        await bee.hive(req, res, model).insert(data)

        bee.response()
    },
    
    put : async function(req, res) {
        let data = req.body
        let id   = req.params.id
        
        await bee.hive(req, res, model).update(data, id)
        
        bee.response()
    },

    search : async function(req, res) {
        let search = req.body.search
        
        res = await bee.hive(req, res, model).search(search, "Name, Email").get()
        
        res.response()
    },
    
    delete : async function(req, res) {
        let id = req.params.id
        
        await bee.hive(req, res, model).delete(id)
        
        bee.response()
    },

    login : async function(req, res) {
        let email    = req.body.Email
        let password = bee.tools.md5(req.body.Password)

        res = await bee
                        .hive(req, res, model)
                        .where("Email = ? AND Password = ?")
                        .binds(email, password)
                        .find()
                        .then(bee => bee.token('jwt').createToken(bee.data))

        res.counters.users
            ? res.response()
            : bee.security.failLogin(req, res, email)
    },
}`
    }
    ],
    middlewares: [
    { 
        title_EN: 'A simple middleware',
        description_EN: '',
        file: 'middlewares.js',
        code:
`module.exports = {
    session: {
        errorCode: 401,
        failCondition: "IDUser == null",
        failAction: { type: "SHOW_FORM", form: "login", message: "NOT AUTHENTICATED" },
    }
}
`
    },
    { 
        title_EN: 'A middleware with permission rules',
        description_EN: '',
        file: 'middlewares.js',
        code:
`module.exports = {
    session: {
        errorCode: 401,
        failCondition: "IDUser == null",
        failAction: { type: "SHOW_FORM", form: "login", message: "NOT AUTHENTICATED" },
    },

    permission: {
        errorCode: 403,
        failCondition: "Endpoint == null",
        failAction: { type: "SHOW_ERROR", message: "NO PERMISSION" },
    }
}
`
    },
    ],
    routes: [
        {
            title_EN: "Default routes",
            description_EN: "Default routes.js file with CRUD of users model",
            file: "routes.js",
            code: `
module.exports = [
    // users
    { route: '/users',          method: 'get',     controller: 'users.get',     model: "users" },
    { route: '/users/:id',      method: 'get',     controller: 'users.find',    model: "users" },
    { route: '/users',          method: 'post',    controller: 'users.post',    model: "users" },
    { route: '/users',          method: 'put',     controller: 'users.put',     model: "users" },
    { route: '/users',          method: 'delete',  controller: 'users.delete',  model: "users" },
]
`
        },
        {
            title_EN: "Free routes",
            description_EN: "Default routes.js file with CRUD of users model and two free routes (routes without middleware rules). Access to these routes will ignore middleware.",
            file: "routes.js",
            code: `
module.exports = [
    // users
    { route: '/users',          method: 'get',     controller: 'users.get',     model: "users" },
    { route: '/users/:id',      method: 'get',     controller: 'users.find',    model: "users" },
    { route: '/users',          method: 'post',    controller: 'users.post',    model: "users" },
    { route: '/users',          method: 'put',     controller: 'users.put',     model: "users" },
    { route: '/users',          method: 'delete',  controller: 'users.delete',  model: "users" },
    { route: '/users/logout',   method: 'delete',  controller: 'users.logout',  model: "users" },

    //users - free route
    { route: '/users/login',    method: 'post',    controller: 'users.login',   model: "users",  free: true },
]
`
        },
    ]
    
    }