import { codes } from "./codes";
import { configsProps } from './configsProps';
import { modelsProps } from "./modelProps";
import { ormProps } from './ormProps';
import { tools } from './tools';
import { deploy } from './deploy';

import _pages from './pages';

export const pages = _pages

export const docs = {
    starting: {
        intro_EN: [
                "For Bee.jsto work, NodeJS must first be installed. For install NodeJS please access the https://nodejs.org.",
            { 
                title_EN: "Install by npm", 
                code: `npm install @bee.js/cli -g` 
            },
            { 
                title_EN: "Create a project", 
                code: `npx create-bee-app api` 
            },
            { 
                title_EN: "Run project in dev mode", 
                code: `cd api\nnpm run dev` 
            },
        ],
        props: null,
        codes: codes.starting
    },

    configs: {
        intro_EN: ['The file configs.js contain the general configurations for your application.'],
        props: configsProps,
        codes: codes.configs
    },

    models: {
        intro_EN: ["Models are objects that you can define the characteristics for your database tables to be used by the ORM, regardless of the database used."],
        props: modelsProps,
        codes: codes.models
    },

    ORM: {
        intro_EN: [`Object-relational mapping`],
        props: ormProps
    },

    controllers: {
        intro_EN: [`Example of a controller for a "users" model CRUD`],
        props: null,
        codes: codes.controllers
    },

    middlewares: {
        intro_EN: [`The middleware contains validation rules for accessing routes. You can set up rules for sessions and permissions.
        The fail conditions use as data the valid JWT payload informed in the requests.
        `],
        props: null,
        codes: codes.middlewares
    },

    routes: {
        intro_EN: [``],
        props: null,
        codes: codes.routes
    },

    tools: {
        intro_EN: ["Bee.jshas some native functions that are common in current projects."],
        propsTitle: "Functions",
        props: tools,
        codes: null
    },

    scheduler: {
        intro_EN: [`Expected in the next version`],
        props: null,
        codes: null
    },

    security: {
        intro_EN: [`Expected in the next version`],
        props: null,
        codes: null
    },

    deploy: {
        intro_EN: [`The projects created in Bee.jsare friendly for deployment in Docker containers.`],
        propsTitle: "Docker",
        props: deploy,
        codes: null
    }
}