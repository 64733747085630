export const tools = {
    md5: {
        description_EN: "returns a string hash using the MD5 algorithm",
        codes: [`bee.tools.md5("string")`]
    },
    sha256: {
        description_EN: "returns a string hash using the SHA256 algorithm",
        codes: [`bee.tools.sha256("string")`]
    },
    guid: {
        description_EN: "returns a GUID/UUID",
        codes: [`bee.tools.guid()\n//or\nbee.tools.uuid()`]
    },
    guidToBin: {
        description_EN: "convert a GUID/UUID to Binary",
        codes: [`bee.tools.guidToBin("00000000-0000-0000-0000-000000000000")\n\n//result: 0x00000000000000000000000000000000`]
    },
    zip: {
        description_EN: "expected in the next version"
    },
    captcha: {
        description_EN: "expected in the next version"
    },
    smtp: {
        description_EN: "expected in the next version"
    },
}