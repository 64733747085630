import React, { useEffect } from 'react'
import { Container } from 'reactstrap';
import { CodeBlock, dracula } from "react-code-blocks";
import { docs } from '../data'

export default function Docs(props) {

  useEffect(function() {

    if(props.doc !== "starting")
      window.location.href="#doc"
      
  }, [props])

  return <Container id="doc">
            <h1 className={props[`title_${props.lang || 'EN'}`] ? "" : "text-capitalize"}>{props[`title_${props.lang || 'EN'}`] || props.doc}</h1>
          {
            docs[props.doc][`intro_${props.lang || 'EN'}`]
            .map(a => typeof(a) == "string"
                        ? <p>{a}</p>
                        : <Container className="mt-4">
                            { a[`title_${props.lang || 'EN'}`] && <h4>{a[`title_${props.lang || 'EN'}`]}</h4> }
                            <CodeBlock
                                text={a.code}
                                language={'javascript'}
                                showLineNumbers={false}
                                theme={dracula}
                              />  
                          </Container>      
            )
          }
          {
            docs[props.doc].props &&
            <h3 className="mb-4 mt-4">{docs[props.doc].propsTitle || "Properties"}</h3>
          }
          {
            docs[props.doc] && 
            docs[props.doc].props &&
            Object.keys(docs[props.doc]?.props)
            .map((prop, index) => <Container key={index} className="mb-4 bee-card">
              <h4>{prop}</h4>
              <p className="mb-0"><strong>Description:</strong> {docs[props.doc].props[prop][`description_${props.lang || 'EN'}`].toLowerCase()}</p>
              {
                docs[props.doc].props[prop].default &&
                <p className="mb-0"><strong>Default:</strong> {docs[props.doc].props[prop].default}</p>    
              }
              { 
                docs[props.doc].props[prop][`ifUndefined_${props.lang || 'EN'}`] && 
                <p><strong>If undefined:</strong> {docs[props.doc].props[prop][`ifUndefined_${props.lang || 'EN'}`].toLowerCase()}</p>
              }
              { 
                docs[props.doc].props[prop].props &&
                  <table width="100%">
                    <thead>
                      <th width="20%">Prop</th>
                      <th>Description</th>
                      <th>Options</th>
                      <th>Default</th>
                    </thead>
                    {
                      Object.keys(docs[props.doc].props[prop].props)
                      .map((prop2, index2) => <tr key={'_' + index2}>
                      <td>{prop2}</td>
                      <td>{docs[props.doc].props[prop].props[prop2]?.description_EN}</td>
                      <td>{docs[props.doc].props[prop].props[prop2]?.options?.join(', ')}</td>
                      <td>{(docs[props.doc].props[prop].props[prop2]?.options || [''])[docs[props.doc].props[prop].props[prop2]?.default || 0]}</td>
                    </tr>)
                    }
                  </table>
              }
              {
                docs[props.doc].props[prop].codes &&
                docs[props.doc].props[prop]?.codes.map(a => 
                  <div className="mt-2">
                    <CodeBlock
                      text={a}
                      language={'javascript'}
                      showLineNumbers={false}
                      theme={dracula}
                    />
                  </div>
                )
              }
            </Container>) 
          }
          { 
            docs[props.doc].codes &&
            <h3 className="mt-5">Examples</h3>
          }
          {
            (docs[props.doc]?.codes || [])
            .map((a) => <Container className="mb-4 bee-card">
                            <h5>{a[`title_${props.lang || 'EN'}`]}</h5>
                            <p>{a[`description_${props.lang || 'EN'}`]}</p>
                            <CodeBlock
                              text={a.code.trim()}
                              language={'javascript'}
                              showLineNumbers={false}
                              theme={dracula}
                            />
                        </Container>
            )
          }
        </Container>
}