export const deploy = {
    Dockerfile: {
        description_EN: "Dockerfile example",
        codes: [`FROM node:11

# Create app directory
WORKDIR /app

# Install nodemon for hot reload
RUN npm install -g nodemon

# Install app dependencies
# A wildcard is used to ensure both package.json AND package-lock.json are copied
# where available (npm@5+)
COPY myApp/api/package*.json ./

RUN npm install pkg.json
# If you are building your code for production
# RUN npm install --only=production

# Bundle app source
COPY myApp/api ./

EXPOSE 1987
CMD [ "node", "app.js" ]

# for build image run:
# docker build -t my-app-name`
        ]
    },

    "docker-compose": {
        description_EN: "docker-compose.yml example",
        codes: [`version: '3.7'

services:
  api:
    image: my-app-name:latest
    deploy:
        mode: global
        working_dir: /app
        command: npm start
        ports:
        - 443:1987`
        ]
    },
}