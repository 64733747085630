export default {
    about: {
        title_EN: `About`,
        content_EN: [
            `Bee.jsis a JavaScript framework for nodeJS that aims to facilitate the creation of API for applications that use the MVC design pattern.`,
            
            `It has integrations with SQL database and uses the ORM (Object-relational mapping) technique for data management, in addition to other tools that optimize development.`,

            `Inspired by some libraries from other languages, such as Laravel, it was developed by Arnaldo Liberal in December 2019, being greatly improved during quarantine.`,
        ]
    },

    contact: {
        title_EN: `Contact Us`,
        content_EN: [
            `For questions please see our documentation.`,

            `Note: We are improving our documentation to answer developer questions.`,

            `For other matters please send an email to the developer: arnaldo.liberal@outlook.com`
        ]
    },
    
}