import React from 'react'
import { Container } from 'reactstrap';
import { Link } from "react-router-dom";

const Footer = function() {
    let lang = 'en'

    return <Container fluid className="p-0">
        <Container fluid className="footer pl-5 pr-5 pt-5 bg-dark text-light">
            <div class="row">
                <div class="col-md p-3">
                    <h4>Bee.js</h4>
                    <p>A JavaScript framework for creating Node.js API</p>
                </div>
                <div class="col-md p-3 list-links">
                    <h4>Documentation</h4>
                    <ul>
                        <li><Link to={`/${lang}/v1-0/starting#docs`}>Starting</Link></li>
                        <li><Link to={`/${lang}/v1-0/configs#docs`}>Configs</Link></li>
                        <li><Link to={`/${lang}/v1-0/middlewares#docs`}>Middlewares</Link></li>
                        <li><Link to={`/${lang}/v1-0/controllers#docs`}>Controllers</Link></li>
                        <li><Link to={`/${lang}/v1-0/models#docs`}>Models</Link></li>
                        <li><Link to={`/${lang}/v1-0/orm#docs`}>ORM</Link></li>
                        <li><Link to={`/${lang}/v1-0/routes#docs`}>Routes</Link></li>
                        <li><Link to={`/${lang}/v1-0/tools#docs`}>Tools</Link></li>
                        <li><Link to={`/${lang}/v1-0/scheduler#docs`}>Scheduler</Link></li>
                        <li><Link to={`/${lang}/v1-0/deploy#docs`}>Deploy</Link></li>
                    </ul>
                </div>
                <div class="col-md p-3 list-links">
                    <h4>Pages</h4>
                    <ul>
                        <li><a href={`/${lang}/#home`}>Home</a></li>
                        <li><Link to={`/${lang}/about`}>About</Link></li>
                        <li><Link to={`/${lang}/contact`}>Contact Us</Link></li>
                    </ul>
                    <h4 className="mt-5">Links</h4>
                    <ul>
                        <li><a target="_BLANK" rel="noopener noreferrer" href="https://www.npmjs.com/package/@bee.js/node">npm</a></li>
                        <li><a target="_BLANK" rel="noopener noreferrer" href="https://www.github.com/beejsgit">GitHub</a></li>
                        <li><a target="_BLANK" rel="noopener noreferrer" href="http://beehive.beejs.org">Beehive IDE Online</a></li>
                    </ul>
                </div>
                <div class="col-md p-3 list-social">
                    <h4>Languages</h4>
                    <ul>
                        <li><a href="/en"> English</a></li>
                    </ul>
                    <h4 className="mt-5">Social Network</h4>
                    <ul>
                        <li><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"></path></svg> <a target="_BLANK" href="https://twitter.com/beejsFeed">Twitter</a></li>
                    </ul>
                </div>
            </div>
            <div id="footer" class="row pt-3">
                <div class="text-center mt-5 p-1 col">Bee.js 2020 - Developed during quarantine ;)</div>
            </div></Container>
        </Container>
}

export default Footer;