export const modelsProps = {
    table: {
        description_EN: 'The name defined for data table.',
        ifUndefined_EN: 'If not defined, the table name will be the model name (object key).'
    },

    schema: {
        description_EN: 'Object contain the fields of database. The prop name is the field name.',
        notRequired_EN: false,
        props: {
            type: {
                description_EN: 'Data type of table field.',
                default: 0,
                options: ['string|varchar', 'char', 'text', 'tinyint', 'smallint', 'int', 'bigint', 'decimal', 'bool|boolean', 'timestamp', 'date', 'datetime', 'guid']
            },
            length: {
                description_EN: 'Size of data.'
            },
            ai: {
                description_EN: 'Set as automatic increment value for the guid and family int fields.',
                default: 0,
                options: [false, true]
            },
            out: {
                description_EN: 'Field name on output api.',
                ifNull: 'if not defined, the field name on output will be the field name on database. If defined as FALSE, the filed not rendering on output api.',
            },
            null: {
                description_EN: 'Set if field can be null',
                default: 0,
                options: [false, true]
            },
            default: {
                description_EN: 'Default value assigned to the field when null',
                options: ['any']
            },
            onInput: {
                description_EN: 'Event called when data inserted or updated',
                default: null,
                options: ['md5', 'guid', 'upper', 'lower']
            },
            dbControl: {
                description_EN: 'Allows the database to control the data field regardless of input',
                default: 0,
                options: [false, true]
            }
        }
    },

    indexes: {
        description_EN: 'Schema fields that will be index',
        props: {
            keys: {},
            unique: {}
        }
    },

    globalJoin: {
        description_EN: 'global scope to default join tables',
        notRequired_EN: true
    },

    globalWhere: {
        description_EN: 'global scope to default data filter',
        notRequired_EN: true
    },

    globalOrder: {
        description_EN: 'global scope to default data order',
        notRequired_EN: true
    },

    relations: {
        description_EN: 'relations of tables',
        notRequired_EN: true
    }

}