export const ormProps = {
    find: {
        description_EN: "find one record by key field",
        codes: [`res = await bee.hive(req, res, 'users').find(123)\nres.response()`]
    },

    get: {
        description_EN: "get multiple records",
        codes: [`res = await bee.hive(req, res, 'users').get()\nres.response()`]
    },

    where: {
        description_EN: "get filtered results from a model",
        codes: [
`res = await bee.hive(req, res, 'users').where("Name = 'Harry Potter'").get()\nres.response()`
,
`//With binds
let email = req.body.Email
let password = bee.tools.md5(req.body.Password)

res = await bee
            .hive(req, res, 'users')
            .where("Email = ?, Password = ?")
            .binds(email, password)
            .get()
            
res.response()
`
]
    },

    insert: {
        description_EN: "insert records",
        codes: [
`res = await bee
             .hive(req, res, 'users')
             .insert({Name: "Harry Potter", Email: "harry.potter@beejs.org", Password: "pass123"})
             
res.response()`]
    },

    update: {
        description_EN: "update records",
        codes: [
`res = await bee.hive(req, res, 'users').update({Active: false})\nres.response()`,
`//update with where
let email = req.middleware.Email
let newPassword = req.body.newPassword

res = await bee
            .hive(req, res, 'users')
            .where("Email = ?")
            .binds(email)
            .update({Password: newPassword})\nres.response()`,
]
    },

    delete: {
        description_EN: "delete records",
        codes: [
`res = await bee.hive(req, res, 'users').delete(1)\nres.response()`,
`// Delete multiple records
res = await bee.hive(req, res, 'users').delete([1,2,3])\nres.response()`
]
    },

    orderBy: {
        description_EN: "sort records by a specific field",
        codes: [
`res = await bee
             .hive(req, res, 'users')
             .orderBy('Name ASC, Birth DESC')
             .get()

res.response()`]
    },

    limit: {
        description_EN: "limit result rows",
        codes: [
`res = await bee
             .hive(req, res, 'users')
             .limit(10)
             .get()

res.response()`,
`
// limit with offset
res = await bee
             .hive(req, res, 'users')
             .limit(20,10)
             .get()

res.response()`
]
    },

    search: {
        description_EN: `The .search () method allows you to search for fields in a table, giving weight to the search relevance conditions. The weights can be defined by a matrix where each position can receive a numerical weight that will result in an ascending order. The default relevance is: [1,2,3,4,5]`,
        codes: [
`let search = "harry potter"
let fields = "Name, Email"

res = await bee.hive(req, res, 'users').search(search, fields).get()
// equivalent to
//res = await bee.hive(req, res, 'users').search(search, fields, [1,2,3,4,5]).get()

res.response()
`,
`// Customizing relevance
let search = "harry potter"
let fields = "Name, Email"
let relevance = [1,2,5,5,5]

// The position of the criteria in the weigth array are:
// 0 = when the search is exactly the same as the field
// 1 = when the search is exactly the beginning of the field
// 2 = when the search is exactly the same somewhere in the field
// 3 = when all search words are found in the field
// 4 = when one of the search words is found in the field

res = await bee.hive(req, res, 'users').search(search, fields, relevance).get()

res.response()
`]
    },

    query: {
        description_EN: "run an explicit database query",
        codes: [`res = await bee.hive(req, res).query("SELECT * FROM users")

res.response()`
]
    },

    relations: {
        description_EN: "relations models"
    },

}