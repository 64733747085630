import React, { useEffect } from 'react'
import { Container } from 'reactstrap';
import { CodeBlock, dracula } from "react-code-blocks";
import { Link, useParams } from "react-router-dom";
import { pages } from '../data'

export default function Page(props) {

  let { page, lang } = useParams()
  
  const data     = pages[page]
  lang           = (lang || "EN").toUpperCase()

  useEffect(function() {
    window.location.href="#page"
  }, [data])

  return <Container id="page">
            <h1 className="mb-4">{data[`title_${lang}`]}</h1>
            {
              data[`content_${lang}`]
              .map((a)=> <p className="text-justify">{a}</p>)
            }
        </Container>
}