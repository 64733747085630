export const configsProps = {
    name: {
        description_EN: "name of application",
        description_BR: "nome da aplicação",
        description_PT: "nome da aplicação",
        description_ES: "nombre de la aplicación",
    },
    version: {
        description_EN: "number of application version",
        description_BR: "numero da versão da aplicação",
    },
    port: {
        description_EN: "http and https port for web server",
        default: 1987.2087
    },
    models: {
        description_EN: "import a models file. See more on the models menu."
    },
    controllers: {
        description_EN: "import a controllers file. See more on the controllers menu."
    },    
    middlewares: {
        description_EN: "import a middlewares file"
    },
    routes: {
        description_EN: "import a routes file. See more on the routes menu."
    },
    databases: {
        description_EN: "set the parameters for the connection to databases"
    },
    jwt: {
        description_EN: "set the configurations for use the JWT (Json Web Token). See more on the JWT menu."
    },
}