import React from 'react';
import { Container, Row, Col, Button, Card, CardTitle, CardText } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import logoJavascript from './assets/images/logo-javascript.png'
import logoNode from './assets/images/logo-node.png'
import logoMysql from './assets/images/logo-mysql.png'

import Docs from './pages/Docs';
import Page from './pages/Page';

import Footer from './Footer';

import './App.css';

export default function App() {

  let lang = "en" //TEMP

  return <Router> 
    <Container id="home" fluid>
          <Row>
            <Container id="banner" fluid>
                <Container>
                  <Row>
                      <h1 color="white">Bee.js</h1> 
                  </Row>
                  <Row>
                  <h5>A JavaScript framework for creating Node.js API</h5>     
                  </Row>
                  <Row>
                    <Button onClick={()=>window.location.href='#doc'} className="mt-2 ml-0">Documentation</Button>
                    <Button onClick={()=>window.location.href='http://beehive.beejs.org'} className="mt-2 ml-5">Beehive Online IDE</Button>
                  </Row>
                </Container>
            </Container>
          </Row>
          <Row className="mt-4">
          <Container className="mt-5 mb-5 page">
            <Row>
              <h1>Code easily with</h1>
            </Row>
            <Row className="mt-5 text-center" id="brands">
              <Col>
                <Card body center className="shadow">
                  <CardTitle>JavaScript</CardTitle>
                  <img src={logoJavascript} />
                  <CardText></CardText>
                </Card>
              </Col>
              <Col>
                <Card body className="shadow">
                  <CardTitle>Node.js</CardTitle>
                  <img src={logoNode} />
                  <CardText></CardText>
                </Card>
              </Col>
              <Col>
                <Card body center className="shadow">
                  <CardTitle>MySQL Database</CardTitle>
                  <img src={logoMysql} />
                  <CardText></CardText>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4" fluid>
              <Col sm="3" className="mt-5 pt-2">
                <h4>Documentation</h4>
                <div>
                  <ul className="menu">
                    <li><Link to={`/${lang}/v1-0/starting`}>Starting</Link></li>
                    <li><Link to={`/${lang}/v1-0/configs`}>Configs</Link></li>
                    <li><Link to={`/${lang}/v1-0/models`}>Models</Link></li>
                    <li><Link to={`/${lang}/v1-0/middlewares`}>Middlewares</Link></li>
                    <li><Link to={`/${lang}/v1-0/controllers`}>Controllers</Link></li>
                    <li><Link to={`/${lang}/v1-0/ORM`}>Hive ORM</Link></li>
                    <li><Link to={`/${lang}/v1-0/tools`}>Tools</Link></li>
                    <li><Link to={`/${lang}/v1-0/scheduler`}>Scheduler</Link></li>
                    <li><Link to={`/${lang}/v1-0/security`}>Security</Link></li>
                    <li><Link to={`/${lang}/v1-0/deploy`}>Deploy</Link></li>
                  </ul>
                </div>
                <h4>Pages</h4>
                <div>
                  <ul className="menu">
                    <li><Link to={`/${lang}/about`}>About</Link></li>
                    <li><Link to={`/${lang}/contact`}>Contact Us</Link></li>
                    <li><a href={`/${lang}/#home`}>Home</a></li>
                  </ul>
                </div>
                <h4>Links</h4>
                <div>
                  <ul className="menu">
                    <li><a target="_blank" href={`https://www.npmjs.com/package/bee-backend`}>npm</a></li>
                    <li><a target="_blank" href={`https://github.com/beejsgit`}>GitHub</a></li>
                  </ul>
                </div>
              </Col>
              <Col sm="9" className="mt-5">
                <Row className="mt-0">
                <Switch>
                  <Route path={['/',`/${lang}`]} exact>
                    <Docs doc={'starting'} />
                  </Route>
                  <Route path={[`/${lang}/v1-0/starting`]} exact>
                    <Docs doc={'starting'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/configs`} exact>
                    <Docs doc={'configs'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/models`} exact>
                    <Docs doc={'models'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/orm`} exact>
                    <Docs doc={'ORM'} title="ORM" />
                  </Route>
                  <Route path={`/${lang}/v1-0/controllers`} exact>
                    <Docs doc={'controllers'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/middlewares`} exact>
                    <Docs doc={'middlewares'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/routes`} exact>
                    <Docs doc={'routes'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/tools`} exact>
                    <Docs doc={'tools'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/scheduler`} exact>
                    <Docs doc={'scheduler'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/security`} exact>
                    <Docs doc={'security'} />
                  </Route>
                  <Route path={`/${lang}/v1-0/deploy`} exact>
                    <Docs doc={'deploy'} />
                  </Route>
                  <Route path={`/${lang}/:page`} exact>
                    <Page />
                  </Route>
                </Switch>
              </Row>
              </Col>
            </Row>
          </Container>
          </Row>
          <Row>
            <Footer />          
          </Row>
    </Container>
 </Router>
}